import React from 'react';

interface SchoolCardProps {
    image: string;
    name: string;
    icon: string;
    classname?: string;
}

const SchoolCard: React.FC<SchoolCardProps> = ({ image, name, icon, classname }) => {
    return (
        <div className={`school-card ${classname}`}>
            <img src={image} alt={name} />
            <p>{name}</p>
            <img src={icon} alt="Icon" />
        </div>
    );
};

export default SchoolCard;