import { ProjectModel } from "../../models/projectModel";
import bookstore from "../../assets/imgs/projects/bookstore/bookstore.png";
import { Tag } from "../types/tags";
import { Opleiding } from "../types/opleiding";
import { Stadium } from "../types/stadium";
import { Stack } from "../types/stack";
import ARTDEFINE_PROJECT from "./artdefine";
import SPOTYFY_REBRANDING_PROJECT from "./spotifyRebranding";
import exp from "constants";
import HOPPIN_PROJECT from "./hoppin";
import FLUVIUS_PROJECT from "./fluvius";
import RUMMICUB_PROJECT from "./rubbicub";
import DELEWARE_PROJECTS from "./delaware";
import BOOKSTORE_PROJECT from "./bookstore";
import STAGE_PROJECT from "./stage";
import CSSPROJECTS_PROJECT from "./cssProjects";
import WIJZEWEETJES_PROJECT from "./wijzeweetjes";
import EVENTS from "./evenementen";
import GEZOUTE_POPCORN from "./gezoute_popcorn";

const projects: ProjectModel[] = [
  BOOKSTORE_PROJECT,
  DELEWARE_PROJECTS,
  RUMMICUB_PROJECT,
  CSSPROJECTS_PROJECT,
  EVENTS,
  FLUVIUS_PROJECT,
  WIJZEWEETJES_PROJECT,
  STAGE_PROJECT,
  HOPPIN_PROJECT,
  SPOTYFY_REBRANDING_PROJECT,
  ARTDEFINE_PROJECT,
  GEZOUTE_POPCORN,
];

export default projects.reverse();
