import { UserModel } from "../../models/userModel";

enum Name {
    CHARLOTTE = "Charlotte",
    DYLAN = "Dylan",
    NIELS = "Niels",
    VIKTOR = "Viktor",
    RUBEN = "Ruben",
    ARNOUD = "Arnoud",
JONATHAN = "Jonathan",
KASPER = "Kasper",
JEROEN = "Jeroen",
TIM = "Tim",
EVI = "Evi",

}


const USER_DATA: UserModel[] = [
    {
        id: "1",
        name: Name.CHARLOTTE,
        lastName: "Overeem",
        url: 'http://google.com'
    },
    {
        id: "2",
        name: Name.DYLAN,
        lastName: "Bracke",
        url: 'http://google.com'
    },
    {
        id: "3",
        name: Name.NIELS,
        lastName: "Van Hove",
        url: 'https://www.linkedin.com/in/niels-van-hove-4ba054234/?originalSubdomain=be'
    }
    ,
    {
        id: "4",
        name: Name.VIKTOR,
        lastName: "Van Puyvelde",
        url: 'https://www.viktorvanpuyvelde.be/'
    },
    {
        id: "5",
        name: Name.RUBEN,
        lastName: "Roelens",
        url: null,
    },
    {
        id: "6",
        name: Name.ARNOUD,
        lastName: "Bury",
        url: null,
    },
    {
        id: "7",
        name: Name.JONATHAN,
        lastName: "Decatelle",
        url: 'https://www.linkedin.com/in/jonathandecatelle/?originalSubdomain=be',
    },
    {
        id: "8",
        name: Name.KASPER,
        lastName: "Vandekerckhove",
        url: 'https://www.linkedin.com/in/kasper-vandekerckhove-354301239/?originalSubdomain=be',
    },
    {
        id: "9",
        name: Name.JEROEN,
        lastName: "Roelant",
        url: 'https://www.linkedin.com/in/jeroen-roelant/',
    },
    {
        id: "10",
        name: Name.TIM,
        lastName: "Grvendonck",
        url: 'https://www.linkedin.com/in/tim-grevendonk/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=be',
    },
    {
        id: "11",
        name: Name.EVI,
        lastName: "de Rudder",
        url: 'https://www.linkedin.com/in/evelien-de-rudder-a35b4b209/?originalSubdomain=be',
    },
];




export const USERS: Record<Name, UserModel> = USER_DATA.reduce((acc, user) => {
    return {
        ...acc,
        [user.name]: user
    };
}, {} as Record<Name, UserModel>);







