import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import logo from "./logo.svg";
import "./App.scss";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Project from "./pages/Project";
import projects from "./data/projects/projects";
import Contact from "./pages/Contact";
import { useEffect } from "react";
import CV from "./pages/CV";
import ProjectList from "./pages/ProjectList";






const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Navigate replace to="/home" />,
      },
      {
        path: "/home",
        children: [
          {
            index: true,
            element: <Home />,
          },
        ],
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      { path: "/projects", element: <ProjectList />, },
      {
        path: "/projects/:projectId",
        element: <Project />,
      },
      {
        path: "/cv",
        element: <CV />,
      },
      {
        path: "*",
        element: <h1>Not Found</h1>,
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    const changeCursor = () => {
      document.addEventListener("selectionchange", function () {
        var selection = window.getSelection();
        if (selection && selection.toString().length > 0) {
          document.body.classList.add("selecting-cursor");
        } else {
          document.body.classList.remove("selecting-cursor");
        }
      });
    };

    const handleLinkHover = () => {
      const links = document.querySelectorAll("a");
      links.forEach((link) => {
        link.addEventListener("mouseover", () => {
          document.body.classList.add("link-hover");
        });
        link.addEventListener("mouseout", () => {
          document.body.classList.remove("link-hover");
        });
      });
    };

    const handleButtonHover = () => {
      const buttons = document.querySelectorAll("button");
      buttons.forEach((button) => {
        button.addEventListener("mouseover", () => {
          document.body.classList.add("button-hover");
        });
        button.addEventListener("mouseout", () => {
          document.body.classList.remove("button-hover");
        });
      });
    };

    handleButtonHover();
    changeCursor();
    handleLinkHover();
  }, []);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
