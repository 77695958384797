import { Opleiding } from "../types/opleiding";
import { Stadium } from "../types/stadium";
import { Tag } from "../types/tags";
import banner from '../../assets/imgs/projects/bookstore/bookstore.png';
import reactapp from '../../assets/imgs/projects/bookstore/bookstore.png';
import frontEndTests from '../../assets/imgs/projects/bookstore/front=end=testing.png';
import { ProjectModel } from "../../models/projectModel";

const BOOKSTORE_PROJECT: ProjectModel =
{
  id: "1",
  naam: "Een online boekenportaal",
  opleiding: Opleiding.ToegepasteInformatica,
  stadium: Stadium.toegepaste_2,
  tags: [Tag.DEV_first, Tag.REACT, Tag.NODE, Tag.CYPRESS],
  inleiding:
    "<p>Dit project was mijn eerste aanraking met REACT en NODE.js. Ik heb een website gemaakt voor een fictief boekenportaal. Hierop konden gebruikers hun gelezen boeken toevoegen en reviews geven.</p>",
  leerdoelen: ["Onder de knie krijgen van REACT", "Leren werken met en opzetten van API's"],
  proces:
    "<p>Door de lessen mee te volgen maakte ik mijn eigen variant van de voorbeelden die werden gegeven. Ik legde de focus vooral op het begrijpen van de concepten.</p>",
  resultaat:
    "<p>Het resultaat was een website die voldeed aan de vereisten van de opdracht en mijn eigen API implementeerde. Er kunnen boeken en auteurs worden toegevoegd en een gebruiker kan een review plaatsen. Er is ook een admin panel voor bevoegden waarop deze boeken en reviews kunnen monitoren en beoordelen.</p>",
  reflectie:
    "<p>De eerste keer werken met REACT vond ik een hele uitdaging. Het grootste deel van mijn tijd in het project ging naar het begrijpen van de werking en concepten van REACT en NODE. Hierdoor had de website zelf maar een matig uitziende UI.</p>",
  gebruikte_middelen: [Tag.REACT, Tag.NODE, Tag.CYPRESS],
  journey: [
    {
      titel: "De Homepage van de website",
      beschrijving: "Op de homepagina kunnen gebruikers hun boeken met reviews bekijken",
      content: reactapp,
    },
    {
      titel: "Front-end testing",
      beschrijving: "Een voorbeeld van een van de front-end tests die zijn afgenomen met Cypress",
      content: frontEndTests,
    },
  ],
  groepswerk: false,

  banner: banner,
  images:
    [
    {
      titel: "De Bookstore website",
      beschrijving: "De homepage van de bookstore website",
      content: reactapp,
    },
    {
      titel: "Front-end testing",
      beschrijving: "Een voorbeeld van een front-end test met Cypress",
      content: frontEndTests,
    }
    ]

}

export default BOOKSTORE_PROJECT;
