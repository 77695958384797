import figmaIcon from "../../assets/type-icons/figma.png";
import reactIcon from "../../assets/type-icons/REACT.svg";
import postgresIcon from '../../assets/type-icons/POSTGRES.png'
import nestIcon from '../../assets/type-icons/NEST.png'
import devFirstIcon from '../../assets/type-icons/dev-design/dev/1-1.svg'
import devSecondIcon from '../../assets/type-icons/dev-design/dev/0-1.svg'
import designFirstIcon from '../../assets/type-icons/dev-design/design/1-1.png'
import designSecondIcon from '../../assets/type-icons/dev-design/design/0-1.png'
import typescriptIcon from '../../assets/type-icons/TYPESCRIPT.png'
import javascriptIcon from '../../assets/type-icons/JAVASCRIPT.png'
import htmlIcon from '../../assets/type-icons/HTML.jpg'
import sassIcon from '../../assets/type-icons/SASS.png'
import bootstrapIcon from '../../assets/type-icons/BOOTSTRAP.png'
import cypressIcon from '../../assets/type-icons/CYPRESS.jpeg'
import androidIcon from '../../assets/type-icons/ANDROID.png'
import javaIcon from '../../assets/type-icons/JAVA.png'
import iosIcon from '../../assets/type-icons/IOS.png'
import swiftUIIcon from '../../assets/type-icons/SWIFTUI.png'
import dotnetIcon from '../../assets/type-icons/DOTNET.png'
import drupalIcon from '../../assets/type-icons/DRUPAL.png'
import phpIcon from '../../assets/type-icons/PHP.png'
import nodeIcon from '../../assets/type-icons/NODE.jpg'
import splineIcon from '../../assets/type-icons/SPLINE.jpg'

export enum Tag {
  DEV_first = "Development",
  DEV_second = "Development ",
  DESIGN_first = "Design",
  DESIGN_second = "Design ",
  REACT = "React",
  NODE = "Node",
  EXPRESS = "Express",
  MONGODB = "MongoDB",
  POSTGRES = "PostgreSQL",
  MYSQL = "MySQL",
  TYPESCRIPT = "TypeScript",
  JAVASCRIPT = "JavaScript",
  HTML = "HTML",
  CSS = "CSS",
  SASS = "SASS",
  BOOTSTRAP = "Bootstrap",
  CYPRESS = "Cypress",
  FIGMA = "Figma",
  ANDROID = "Android",
  JAVA = "Java",
  SWIFTUI = "SwiftUI",
  NEST = "Nest",
  IOS = "iOS",
  DOTNET = ".NET",
  DRUPAL = "DRUPAL",
  PHP = "PHP",
  SPLINE = "Spline",
  empty = "",
}

const tagIcons: Record<Tag, string> = {
  [Tag.REACT]: reactIcon,
  [Tag.NODE]: nodeIcon,
  [Tag.EXPRESS]: figmaIcon,
  [Tag.MONGODB]: figmaIcon,
  [Tag.POSTGRES]: postgresIcon,
  [Tag.MYSQL]: figmaIcon,
  [Tag.TYPESCRIPT]: typescriptIcon,
  [Tag.JAVASCRIPT]: javascriptIcon,
  [Tag.HTML]: htmlIcon,
  [Tag.CSS]: figmaIcon,
  [Tag.SASS]: sassIcon,
  [Tag.BOOTSTRAP]: bootstrapIcon,
  [Tag.CYPRESS]: cypressIcon,
  [Tag.FIGMA]: figmaIcon,
  [Tag.ANDROID]: androidIcon,
  [Tag.JAVA]: javaIcon,
  [Tag.DEV_first]: devFirstIcon,
  [Tag.DEV_second]: devSecondIcon,
  [Tag.DESIGN_first]: designFirstIcon,
  [Tag.DESIGN_second]: designSecondIcon,
  [Tag.IOS]: iosIcon,
  [Tag.SWIFTUI]: swiftUIIcon,
  [Tag.NEST]: nestIcon,
  [Tag.DOTNET]: dotnetIcon,
  [Tag.DRUPAL]: drupalIcon,
  [Tag.PHP]: phpIcon,
  [Tag.SPLINE]: splineIcon,
  [Tag.empty]: '',
};

export { tagIcons };
