import { Opleiding } from "../types/opleiding";
import { Stadium } from "../types/stadium";
import { Tag } from "../types/tags";
import banner from '../../assets/imgs/projects/rummicub/banner/banner.png'
import activityDiagram from '../../assets/imgs/projects/rummicub/activiy diagram.png'
import dcd from '../../assets/imgs/projects/rummicub/dcd.png'
import speelbord from '../../assets/imgs/projects/rummicub/speelbord.jpg'
import { ProjectModel } from "../../models/projectModel";
import { USERS } from "../users/users";

const RUMMIKUB_PROJECT : ProjectModel = {
    id: "3",
    naam: "Java Rummikub Game",
    opleiding: Opleiding.ToegepasteInformatica,
    stadium: Stadium.toegepaste_1,
    tags: [Tag.DEV_first, Tag.JAVA],
    inleiding:
      "<p>Dit was het eerste groepswerk van mijn studie Toegepaste Informatica, we moesten in groep een rummikub game maken met Java(Fx).</p>",
    leerdoelen: ["Leren werken in groep", "Een volledige applicatie bouwen", "Implementeren van Java en JavaFX"],
    proces:
      "<p>Na een analyse te doen van de game en de regels, begonnen we met het maken van een UML diagram. We verdeelden de taken onder elkaar en begonnen met de implementatie. We hadden regelmatig meetings om te zien of iedereen nog op schema zat.</p>",
    resultaat:
      "<p>Het resultaat was een volledige rummikub game die voldeed aan de verreisten van de opdracht en functioneel volledig is uitgwerkt.</p>",
    reflectie:
      "<p>Dit was een zeer geslaagd project, we hadden een goede samenwerking en iedereen was actief. Het was een goede oefening om in groep te werken en te zien hoe een volledige applicatie wordt opgebouwd.</p>",
    gebruikte_middelen: [Tag.JAVA],
    journey: [
        {
            titel: "Activity Diagram",
            beschrijving:
            "Het project begon met het maken van een activity diagram om de verschillende stappen van de game te bepalen. Dit was een belangrijke stap om de structuur en functionaliteit van de game te bepalen.",
            content: activityDiagram,
        },
        {
            titel: "Databank Schema",
            beschrijving:
            "Na het bepalen van de functionaliteiten van de game zijn we overgegaan naar het maken van een databank schema. Dit was een belangrijke stap om de structuur van de databank te bepalen en een duidelijk overzicht te krijgen van de verschillende tabellen en relaties.",
            content: dcd,
        },
        {
            titel: "Speelbord",
            beschrijving:
            "Het speelbord van de rummikub game. Hier zie je een voorbeeld van hoe de game eruit ziet.",
            content: speelbord,
        },
      
    ],
    groepswerk: true,
    leden: [USERS.Niels],
    banner: banner,
    images : [
      {
        src: activityDiagram,
        title: "Activity Diagram",
        description: "Het activity diagram",
      },
      {
        src: dcd,
        title: "Databank Schema",
        description: "Het databank schema",
      },
      {
        src: speelbord,
        title: "Speelbord",
        description: "Het speelbord van de rummikub game",
      },
   ]
  };

export default RUMMIKUB_PROJECT;
