import { Opleiding } from "../types/opleiding";
import { Stadium } from "../types/stadium";
import { Tag } from "../types/tags";
import banner from "../../assets/imgs/projects/spotifyRebrand/banner/banner.png";
import moodboard from "../../assets/imgs/projects/spotifyRebrand/moodboard.png";
import kleurenpalet from "../../assets/imgs/projects/spotifyRebrand/kleurenpallet.png";
import persona from "../../assets/imgs/projects/spotifyRebrand/persona.png";
import typografie from "../../assets/imgs/projects/spotifyRebrand/typografie.png";
import brandsheet from "../../assets/imgs/projects/spotifyRebrand/brandsheet.png";
import websiteWireframe from "../../assets/imgs/projects/spotifyRebrand/website-wireframe.png";
import websitePrototype from "../../assets/imgs/projects/spotifyRebrand/website-prototype.png";
import appWireframe from "../../assets/imgs/projects/spotifyRebrand/app-wireframe.png";
import appPrototype from "../../assets/imgs/projects/spotifyRebrand/app-prototype.png";
import mockup from "../../assets/imgs/projects/spotifyRebrand/mockup.png";
import { ProjectModel } from "../../models/projectModel";

const SPOTIFY_REBRANDING_PROJECT: ProjectModel = {
  id: "9",
  naam: "Spotify Rebranding",
  opleiding: Opleiding.DigitalExperienceDesign,
  stadium: Stadium.xd_1,
  tags: [Tag.DESIGN_first, Tag.FIGMA],
  inleiding:
    "<p>We krijgen de opdracht een redesign te doen van Spotify voor een specifieke doelgroep, ik koos voor de doelgroep: personen die muziek gebruiken om te slapen </p>",
  leerdoelen: [
    "Ontwerpen in Figma",
    "Rebranding",
    "Focussen op specifieke doelgroep",
  ],
  proces:
    "<p>Ik heb Spotify een nieuwe look gegeven voor een specifieke doelgroep: personen die muziek gebruiken om te slapen. Het ontwerp is rustig en donker, om een kalmerende sfeer te creëren. Hiervoor heb ik ook een nieuwe naam en logo bedacht. Dit project is uitgewerkt in Figma en is bedoeld als een prototype.</p>",
  resultaat:
    "<p>Het resultaat was een volledig nieuw ontwerp van Spotify genaamd Sopios. Het ontwerp is rustig en donker, om een kalmerende sfeer te creëren.</p>",
  reflectie:
    "<p>Ik vond het een leuke ervaring om Spotify een nieuwe look te geven voor een specifieke doelgroep. Het was een goede oefening om te zien hoe belangrijk het is om te focussen op een specifieke doelgroep en daarop te ontwerpen. Het was ook de eerste keer dat ik een prototype heb gemaakt en dat was een interessante ervaring.</p>",
  gebruikte_middelen: [Tag.FIGMA],
  journey: [
    {
      titel: "Een moodboard gelinkt aan het doelpubliek",
      beschrijving:
        "Het project begon met het maken van een moodboard om de sfeer van het ontwerp te bepalen. Het moodboard was een combinatie van kleuren, vormen en stijlen die de doelgroep aansprak. Het werd gebruikt als basis van het design.",
      content: moodboard,
    },
    {
      titel: "Een kleurenpalet voor een kalmerende sfeer",
      beschrijving:
        "Na het moodboard ben ik overgegaan naar het bepalen van een kleurenpalet. Dit was een belangrijke stap om de sfeer van het ontwerp te bepalen. Voor dit project heb ik gekozen voor donkere kleuren om een kalmerende sfeer te creëren.",
      content: kleurenpalet,
    },
    {
      titel: "Persona's van de doelgroep",
      beschrijving:
        "Na het bepalen van de sfeer en het kleurenpalet ben ik verder gegaan met het bepalen van de doelgroep. Dit deed ik door het maken van persona's. Dit zijn fictieve personen die de doelgroep representeren. Het helpt om te focussen op de doelgroep en hun noden en wensen.",
      content: persona,
    },
    {
      titel: "Typografie voor een rustig ontwerp",
      beschrijving:
        "Na het bepalen van de doelgroep ben ik overgegaan naar het bepalen van de typografie. Dit was een cruciale stap om de sfeer van het ontwerp te bepalen. Voor dit project heb ik gekozen voor een rustige en leesbare typografie.",
      content: typografie,
    },
    {
      titel: "Brandsheet voor een consistent ontwerp",
      beschrijving:
        "Na het bepalen van de typografie kan alles worden samengevoegd in een brandsheet. Dit was een belangrijke stap om een consistent ontwerp te creëren. Het brandsheet bevat alle elementen van het ontwerp zoals de kleuren, typografie en logo.",
      content: brandsheet,
    },
    {
      titel: "Wireframes voor een gestructureerd ontwerp",
      beschrijving:
        "Na het maken van het brandsheet konden er wireframes worden gemaakt. Dit was een belangrijke stap om de structuur van het ontwerp te bepalen en een look and feel te krijgen van het uiteindelijke product.",
      content: websiteWireframe,
    },
    {
      titel: "Prototype voor een interactief ontwerp",
      beschrijving:
        "De wireframes zijn geëvolueerd naar een werkend prototype in Figma. Dit prototype werd gebruikt om aan usertesting te doen met gebruikers en feedback te krijgen over het design en de usability van het ontwerp.",
      content: websitePrototype,
    },
    {
      titel: "App Wireframes",
      beschrijving:
        "De Wireframes van de app",
      content: appWireframe,
    },
    {
      titel: "App Prototype",
      beschrijving:
        "Het prototype van de app",
      content: appPrototype,
    },
    {
      titel: "Mockup",
      beschrijving:
        "Na het maken van het app prototype zijn we overgegaan naar het maken van een mockup van de app. Dit was een belangrijke stap om een realistische weergave van de app te krijgen en te zien hoe het er in het echt zou uitzien.",
      content: mockup,
    },
  ],
  groepswerk: false,
  banner: banner,
  images: [
    {
      src: moodboard,
      title: "Moodboard",
      description: "Moodboard",
    },
    {
      src: kleurenpalet,
      title: "Kleurenpalet",
      description: "Kleurenpalet",
    },
    {
      src: persona,
      title: "Persona",
      description: "Persona",
    },
    {
      src: typografie,
      title: "Typografie",
      description: "Typografie",
    },
    {
      src: brandsheet,
      title: "Brandsheet",
      description: "Brandsheet",
    },
    {
      src: websiteWireframe,
      title: "Website Wireframe",
      description: "Website Wireframe",
    },
    {
      src: websitePrototype,
      title: "Website Prototype",
      description: "Website Prototype",
    },
    {
      src: appWireframe,
      title: "App Wireframe",
      description: "App Wireframe",
    },
    {
      src: appPrototype,
      title: "App Prototype",
      description: "App Prototype",
    },
    {
      src: mockup,
      title: "Mockup",
      description: "Mockup",
    },
    {
      src: banner,
      title: "Banner",
      description: "Banner",
    },
  ],
};

export default SPOTIFY_REBRANDING_PROJECT;


