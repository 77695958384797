import { ProjectModel } from "../../models/projectModel";
import { Opleiding } from "../types/opleiding";

import { Stadium } from "../types/stadium";
import { Tag } from "../types/tags";
import banner from "../../assets/imgs/projects/artdefine/banner/artdefine_benner.png";
import moodboard from "../../assets/imgs/projects/artdefine/moodboard-final-1.png";
import user_flow from "../../assets/imgs/projects/artdefine/full.png";
import wireframe from "../../assets/imgs/projects/artdefine/wireframe.png";
import prototype from "../../assets/imgs/projects/artdefine/prototype.png";
import technologie from '../../assets/imgs/projects/artdefine/technologie.png'
import { USERS } from "../users/users";
import onePager from '../../assets/imgs/projects/artdefine/onepager.png'
import screens1 from '../../assets/imgs/projects/artdefine/screens1.png'
import screens2 from '../../assets/imgs/projects/artdefine/screens2.png'
import screens3 from '../../assets/imgs/projects/artdefine/screens3.png'

const ARTDEFINE_PROJECT: ProjectModel = {
  id: "10",
  naam: "Eindwerk: Artdefine",
  opleiding: Opleiding.DigitalExperienceDesign,
  stadium: Stadium.xd_1,
  tags: [Tag.DESIGN_first, Tag.DEV_second, Tag.FIGMA, Tag.REACT, Tag.TYPESCRIPT],
  inleiding:
    "<p>Artdefine is een online platform dat een plek geeft aan beginnende creatievelingen die online hun werk willen laten zien en feedback willen krijgen in een veilige omgeving. Deze veilige omgeving wordt gecreëerd door de gebruiker zelf te laten beslissen uit opties om feedback op zijn werk te krijgen.</p>",
  leerdoelen: ["Een app ontwerpen passend bij een bepaalde doelgroep", "Een design valideren aan de hand van usertests"],
  proces:
    "<p>Het project is begonnen met een uitgebreide researchfase waarin gezocht werd naar de behoeften van de gebruiker. Vervolgens is er een design system opgezet dat kleuren en stijl heeft vastgelegd. Na validatie bij de gebruiker is de designfase begonnen die heeft geleid tot het uiteindelijke product, een prototype. Ten slotte is het project functioneel uitgewerkt door middel van een React-website met een Nest-backend.</p>",
  resultaat:
    "<p>Artdefine heeft aan zijn voorwaarden voldaan en is een plek geworden waar de gebruiker zijn eigen profiel heeft waarop hij zijn werken kan plaatsen en gerichte feedback kan vragen. Er zijn ook groepen waar gebruikers zich bij kunnen aansluiten om gelijkgestemden te vinden, te chatten en ook hier werken te plaatsen.</p>",
  reflectie: "<p>Artdefine was een groot project waar we met twee personen aan hebben gewerkt. De grootste uitdaging was het project op poten zetten terwijl we de designkennis gedurende het jaar hebben opgedaan. Dit was op momenten lastig, maar ook een goede manier om direct inzichten toe te passen op een project.</p>",
  gebruikte_middelen: [Tag.FIGMA, Tag.REACT, Tag.NEST, Tag.POSTGRES, Tag.TYPESCRIPT],
  journey: [
    {
      titel: "User Flow van het project",
      beschrijving:
        "Het project begon met het maken van een user flow om de verschillende stappen van de gebruiker te bepalen. Dit was een belangrijke stap om de structuur en functionaliteiten van het platform te bepalen.",
      content: user_flow,
    },

    {
      titel: "Een moodboard voor jonge creatievelingen",
      beschrijving:
        "Na het bepalen van de functionaliteit van het platform gingen we over naar het bepalen van het design. Dit deden we door een moodboard te maken om de sfeer van het platform te bepalen. Het moodboard was een combinatie van kleuren, vormen en stijlen die de doelgroep aansprak. Het werd gebruikt als basis voor het design.",
      content: moodboard,
    },
    {
      titel: "Wireframes als basis van het platform",
      beschrijving:
        "Na het moodboard zijn we overgegaan naar het maken van wireframes. Dit was een belangrijke stap om de structuur van de website te bepalen en een look and feel te krijgen van het uiteindelijke product.",
      content: wireframe,
    },
    {
      titel: "Een interactief prototype",
      beschrijving:
        "Na de wireframes zijn we overgegaan naar het maken van een werkend prototype in Figma. Dit prototype werd gebruikt om gebruikerstests uit te voeren en feedback te krijgen over het design en de gebruiksvriendelijkheid van het platform.",
      content: prototype,
    },
    {
      titel: "De 3 technologische pijlers van Artdefine",
      beschrijving:
        "Voor de implementatie van het platform hebben we gekozen voor React en Next.js. Dit zijn twee populaire frameworks die in combinatie zorgen voor een naadloze implementatie. De data wordt opgeslagen in een Postgres-database.",
      content: technologie,
    },
    {
      titel: "Artdefine One pager",
      beschrijving:
        "Een one pager van het project gemaakt in Figma om een indruk te geven van het uiteindelijke project.",
      content: onePager,
    },

  ],
  groepswerk: true,
  leden: [USERS.Charlotte],
  banner: banner,
  images: [{
    src: banner,
    title: 'Artdefine banner',
    description: 'De banner van het project Artdefine'
  },
  {
    src: moodboard,
    title: 'Artdefine moodboard',
    description: 'Het moodboard van het project Artdefine'
  },
  {
    src: user_flow,
  
    title: 'Artdefine user flow',
    description: 'De user flow van het project Artdefine'
  },
  {
    src: wireframe,
    title: 'Artdefine wireframe',
    description: 'Het wireframe van het project Artdefine',
  },
  {
    src: prototype,
    title: 'Artdefine prototype',
    description: 'Het prototype van het project Artdefine',
  },
  
  {
    src: screens1,
    title: 'Artdefine screens',
    description: 'De screens van het project Artdefine',
  },
  {
    src: screens2,
    title: 'Artdefine screens',
    description: 'De screens van het project Artdefine',
  },
  {
    src: screens3,
    title: 'Artdefine screens',
    description: 'De screens van het project Artdefine',
  },
  {
    src: technologie,
    title: 'Artdefine technologie',
    description: 'De technologie van het project Artdefine',
  },
  {
    src: onePager,
    title: 'Artdefine one pager',
    description: 'De one pager van het project Artdefine',
  }

]
};

export default ARTDEFINE_PROJECT;
