import icon from '../../assets/vectors/Ellipse.svg'

interface JourneyCardProps {
    title: string;
    description: string;
    picture?: string;
}

const JourneyCard: React.FC<JourneyCardProps> = ({ title, description, picture }) => {
    return (
        <div className="journey-card">
            <div className="icon">
                <img src={icon} alt="icon" />
            </div>
            <div className="info"> 
            <h2>{title}</h2>
            <p>{description}</p></div>
           
            <div className="picture">
            {picture && <img src={picture} alt={title} />}
            </div>
        </div>
    );
};

export default JourneyCard;