import cv from "../assets/imgs/DYLAN-BRACKE-Resume.png"
import pdf from '../assets/imgs/DYLAN-BRACKE-Resume.pdf'

const CV: React.FC = () => {
    const downloadCV = () => {
        const link = document.createElement('a');
        link.href = pdf;
        link.download = 'DYLAN-BRACKE-Resume.pdf';
        link.click();
    };

    return (
        <div className='Cv'>
            <h1>Curriculum Vitae </h1>
            <button  onClick={downloadCV} className="btn">Download</button>
            <img src={cv} title="Resume" />
          
           
        </div>
    );
};

export default CV;