import React from 'react';
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { ReactComponent as Logo} from '../../assets/vectors/logo.svg'
 



export const CredentialsCard: React.FC = () => {
    return (
        <div className="credentials-card">
            <Logo/>
            <div className="name">Dylan Bracke</div>
            <div className="title"><span className='font-basker'>CREATIVE</span> DEVELOPER</div>
            <div className="social-links">
                <a href="https://github.com/DylanBracke" target="_blank" rel="noopener noreferrer">
                    <FaGithub className="social-icon" />
                </a>
                <a href="https://www.linkedin.com/in/dylan-bracke/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin className="social-icon" />
                </a>
            </div>
        </div>
    );
};

export default CredentialsCard;