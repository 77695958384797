import { Opleiding } from "../types/opleiding";
import { Stadium } from "../types/stadium";
import { Tag } from "../types/tags";
import banner from '../../assets/imgs/projects/wijzeweetjes/banner/banner.png';
import { ProjectModel } from "../../models/projectModel";
import briefOfConcept from '../../assets/imgs/projects/wijzeweetjes/boc.png';
import brainstorming from '../../assets/imgs/projects/wijzeweetjes/brainstormfoto.jpg';
import schetsen from '../../assets/imgs/projects/wijzeweetjes/schetsen.jpg';
import { USERS } from "../users/users";
import homepage from '../../assets/imgs/projects/wijzeweetjes/homepage.png';
import lvl1 from '../../assets/imgs/projects/wijzeweetjes/lvl1.png';
import lvl2 from '../../assets/imgs/projects/wijzeweetjes/lvl2.png';

const WIJZEWEETJES_PROJECT: ProjectModel = {
  id: "12",
  naam: "WijzeWeetjes: Een 3D kinderspel",
  opleiding: Opleiding.DigitalExperienceDesign,
  stadium: Stadium.xd_1,
  tags: [Tag.DESIGN_first, Tag.SPLINE],
  inleiding:
    "<p>Als Design opdracht kwam Pelckmans met het probleem dat ze meer kinderen naar hun boekenwinkel willen laten komen. Een online experience leek voor hen de ideale oplossing. Van de vele boeken is dan gekozen rond het boek 'Wijze Weetjes', een speels informatief boek voor kinderen. Dit project was ook in opdracht van het research team van Thomas More die interesse had in online 3D design/werelden. Hierdoor werd de keuze gemaakt om met Spline te werken, een 3D UI-platform met opties om game mechanics te implementeren.</p>",
  leerdoelen: ["Het leren van een nieuw programma (Spline)", "Designen voor een specifieke doelgroep"],
  proces:
    "<p>Allereerst zijn we op gesprek geweest met Pelckmans, de boekenwinkel Pardoes en de auteurs van het boek 'Wijze Weetjes' om volledig mee te zijn met de behoeften van de gebruiker en de visie van de winkel/auteurs. Na de researchfase kwam een lange brainstormfase om tot een gepast concept te komen. Na het concept werden er schetsen gemaakt en werd de game geïmplementeerd in Spline.</p>",
  resultaat:
    "<p>Het resultaat was een online 3D-wereld waarin kinderen op een speelse manier de weetjes van het boek 'Wijze Weetjes' kunnen ontdekken. De wereld is opgedeeld in verschillende levels die elk een thema van het boek behandelen. De kinderen kunnen door de wereld navigeren en interactieve elementen ontdekken die hen meer informatie geven over het thema. Het doel van de game is om alle weetjes te ontdekken en zo verder in het boek te komen.</p>",
  reflectie:
    "<p>Dit project is uitgedraaid in een echt passieproject van de groep. Het volledige proces doorlopen was een interessante ervaring die veel inzichten heeft gegeven in het designproces.</p>",
  gebruikte_middelen: [Tag.SPLINE],
  journey: [
    
    {
      titel: "Brainstorming",
      beschrijving: "Brainstormen over het concept",
      content: brainstorming,
    },
    {
      titel: "Schetsen",
      beschrijving: "Schetsen van een level",
      content: schetsen,
    },
    {
      titel: "Brief of Concept dossier",
      beschrijving: "Een overzicht beschreven overzicht van het volledige concept",
      content: briefOfConcept,
     },
    {
      titel: "Homepage",
      beschrijving: "De homepage van de game waar een speler een boek kan kiezen dat hij/zij wilt ontdekken.",
      content: homepage,
    },
    {
      titel: "Eerste level",
      beschrijving: "In dit eerste level moet een speler de top behalen om verder te kunnen. Het weetje wordt ook voorgelezen door een stem.",
      content: lvl1,
    },
    {
      titel: "Tweede level",
      beschrijving: "De speler kan door als hij alle rode knoppen in het doolhof heeft gevonden",
      content: lvl2,
    }
  ],
  groepswerk: true,
  leden: [
    USERS.Jeroen, USERS.Tim, USERS.Evi
  ],
  banner: homepage,
  images: 
    [
    {
      src: briefOfConcept,
      alt: "Brief of concept"
    },
    {
      src: brainstorming,
      alt: "Brainstorming"
    },
    {
      src: schetsen,
      alt: "Schetsen"
    },
    {
      src: homepage,
      alt: "Homepage"
    },
    {
      src: lvl1,
      alt: "Level 1"
    },
    {
      src: lvl2,
      alt: "Level 2"
    }
    ]
  
}

export default WIJZEWEETJES_PROJECT;
