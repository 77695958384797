import React, { useEffect } from 'react';
import ProjectCard from '../components/cards/ProjectCard';
import projects from '../data/projects/projects';

const ProjectList: React.FC = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <h1>Projecten</h1>
            <div className='ProjectList'>
                {projects.map((project, index) => (
                    <ProjectCard id={project.id} key={index} banner={project.banner} title={project.naam} tags={project.tags} isGroupWork={project.groepswerk} />
                ))}
            </div>
        </>
    );
};

export default ProjectList;