import  { ReactNode } from 'react';

interface InfocardProps {
    title: string;
    content: ReactNode;
    className?: string;
}

const Infocard: React.FC<InfocardProps> = ({ title, content, className }) => {
    return (
        <div className="info-card">
            <h3>{title}</h3>
            <div className={`infocard-content ${className }`}>{content}</div>
        </div>
    );
};

export default Infocard;