import Infocard from "../components/cards/Infocard";
import { Opleiding } from "../data/types/opleiding";
import { ProjectModel } from "../models/projectModel";
import TIIcon from "../assets/imgs/HOGENT_Logo.png";
import XDIcon from "../assets/imgs/THOMASMORE_logi.png";
import StackCard from "../components/cards/StackCard";
import JourneyCard from "../components/cards/JourneyCard";
import { useParams } from "react-router-dom";
import projects from "../data/projects/projects";
import { useEffect } from "react";
import group from "../assets/imgs/group.png";
import LightGallery from "lightgallery/react";
import "lightgallery/scss/lightgallery.scss";
import "lightgallery/scss/lg-zoom.scss";
import { LightGallery as ILightGallery } from "lightgallery/lightgallery";
import lgZoom from "lightgallery/plugins/zoom";
import lgThumbnail from "lightgallery/plugins/thumbnail";

const Project: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { projectId } = useParams();
  const project = projects.find((project) => project.id === projectId);
  if (!project) {
    return <div>Project not found.</div>;
  }
  return (
    <div className="Project">
      <h1>
        {project.opleiding === Opleiding.ToegepasteInformatica ? (
          <img src={TIIcon} alt="Toegepaste Informatica" />
        ) : (
          <img src={XDIcon} alt="Digital Experience Design" />
        )}{" "}
        - {project.naam}{" "}
        {project.groepswerk === true ? (
          <img src={group} className="group-icon" alt="" />
        ) : (
          ""
        )}
      </h1>
      <div className="info-and-journey">
        <div className="info">
          <Infocard
            title="Inleiding"
            content={
              <div dangerouslySetInnerHTML={{ __html: project.inleiding }} />
            }
          />
          <div className="leerdoelen">
            <Infocard
              title="Leerdoelen"
              content={
                <ul>
                  {project.leerdoelen.map((doel, index) => (
                    <li key={index}>{doel}</li>
                  ))}
                </ul>
              }
            />
          </div>
          <Infocard
            title="Proces"
            content={
              <div dangerouslySetInnerHTML={{ __html: project.proces }} />
            }
          />
          <div className="stack">
            <Infocard
              title="Stack"
              className="stack"
              content={project.gebruikte_middelen.map((middel, index) => (
                <StackCard name={middel} />
              ))}
            />
          </div>
        </div>

        <div className="journeys">
          {project.journey.map((stap, index) => (
            <JourneyCard
              key={index}
              title={stap.titel}
              description={stap.beschrijving}
              picture={stap.content}
            />
          ))}
        </div>
      </div>
      <div className="result">
        <Infocard
          title="Resultaat"
          content={
            <div dangerouslySetInnerHTML={{ __html: project.resultaat }} />
          }
        />
      </div>
      <div className="reflection">
        <Infocard
          title="Reflectie"
          content={
            <div dangerouslySetInnerHTML={{ __html: project.reflectie }} />
          }
        />
      </div>

      {(project.groepswerk === true && project.leden?.length) ?? 0 > 0 ? (
        <div className="group">
          <Infocard
            title="Groepsleden"
            content={
              <ul>
                {project.leden?.map((user) => (
                  <li>
                    {user.url ? (
                      <a target="_blank" href={user.url}>
                        {user.name} {user.lastName}
                      </a>
                    ) : (
                      `${user.name} ${user.lastName}`
                    )}
                  </li>
                ))}
              </ul>
            }
          />
        </div>
      ) : null}

      {project.images?.length ?? 0 > 0 ? (
        <>
          <h2 className="font align-left">GALLERIJ</h2>
          <div className="images">
            <LightGallery
              elementClassNames={"gallery"}
              plugins={[lgZoom]}
              speed={500}
            >
              {project.images?.map((image, index) => (
                <a key={index} href={image.src} data-lg-size="1280-853">
                  <img src={image.src} alt={image.title} />
                </a>
              ))}
            </LightGallery>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Project;
