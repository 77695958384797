import { ProjectModel } from "../../models/projectModel";
import { Opleiding } from "../types/opleiding";
import { Stadium } from "../types/stadium";
import { Tag } from "../types/tags";
import banner from '../../assets/imgs/projects/events/banner/banner.png';
import futureEntrepreneurship from '../../assets/imgs/projects/events/futureEntrepeneurship.png';
import nxtUp from '../../assets/imgs/projects/events/NXTUP.png';
import forward from '../../assets/imgs/projects/events/forward.jpg';
import privacyEnSecurity from '../../assets/imgs/projects/events/privacyEnSecurity.png';

const EVENTS: ProjectModel = 
{
  id: "13",
  naam: "Interessante evenementen",
  opleiding: Opleiding.ToegepasteInformatica,
  stadium: Stadium.toegepaste_2,
  tags: [Tag.DEV_first, Tag.DESIGN_first],
  inleiding:
    "<p>Tijdens mijn opleiding heb ik ook aan tal van evenementen deelgenomen, helaas zorgde Corona ervoor dat dit niet altijd even makkelijk was.</p>",
  leerdoelen: [
    "Verkennen van de IT-wereld",
    "Laten inspireren door anderen",
    
  ],
  proces:
    "<p>Ik heb deelgenomen aan verschillende evenementen zoals Future Entrepreneurship die de nieuwste technologieën tentoonstelde, NxtUp die een verbreding van interesses gaf met lightning talk en workshops, Forward Festival waar de Design wereld op zijn kop werd gezet,...  </p>",
  resultaat:
    "<p>Ik heb veel bijgeleerd en ben geïnspireerd geraakt door de verschillende sprekers en workshops. Het was een goede oefening om te zien wat er allemaal mogelijk is in de IT-wereld en hoe breed het werkveld is. </p>",
  reflectie:
    " <p>Ik vond het een leuke ervaring om deel te nemen aan verschillende evenementen. Het was een goede manier om te zien wat er allemaal mogelijk is in de IT-wereld en hoe breed het werkveld is. Het heeft me geïnspireerd om verder te gaan in de IT en om te blijven leren en ontwikkelen.</p>",
  gebruikte_middelen: [],
  journey: [
     {
    titel: "Future Entrepreneurship",
    beschrijving: "Future Entrepreneurship was een evenement die de nieuwste technologieën tentoonstelde.",
    content: futureEntrepreneurship,
     },
     {
      titel: "NXTUP",
      beschrijving: "NXTUP was een evenement vol snelle en interessante lightning talks. Er was ook de mogelijkheid om workshops te volgen, dit bracht mij tot mijn eerste aanraking met Figma.",
      content: nxtUp,
     },
     {
      titel: "Forward Festival (Hamburg)",
      beschrijving: "Forward Festival in Hamburg was een tweedaags evenement met twee podia en verschillende standjes. Er kwamen sprekers van over de hele wereld hun verhaal doen over wat voor hen design is of een showreel geven van hun werk.",
      content: forward,
     },
      {
      titel: "Privacy en Security Meetup",
      beschrijving: "Event met verschillende sprekers over privacy en security in de IT-wereld waaronder trolls en hacking.",
      content: privacyEnSecurity,
      },
   
  ],
  groepswerk: false,
  leden: [],
  banner: banner,
  images : [
    {
      src: futureEntrepreneurship,
      title: "Future Entrepreneurship",
      description: "Future Entrepreneurship was een evenement die de nieuwste technologieën tentoonstelde."
    },
    {
      src: nxtUp,
      title: "NXTUP",
      description: "NXTUP was een evenement vol snelle en interessante lightning talks. Er was ook de mogelijkheid om workshops te volgen, dit bracht mij tot mijn eerste aanraking met Figma."
    },
    {
      src: forward,
      title: "Forward Festival (Hamburg)",
      description: "Forward Festival in Hamburg was een tweedaags evenement met twee podia en verschillende standjes. Er kwamen sprekers van over de hele wereld hun verhaal doen over wat voor hen design is of een showreel geven van hun werk."
    },
    {
      src: privacyEnSecurity,
      title: "Privacy en Security",
      description: "Event met verschillende sprekers over privacy en security in de IT-wereld waaronder trolls en hacking."
    },
    
   ]
};

export default EVENTS;
