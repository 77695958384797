import { Outlet } from "react-router-dom";

import React, { Key, useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as Hamburger } from "../assets/vectors/hamburger0.svg";
import { ReactComponent as NameIcon } from '../assets/vectors/DYLANBRACKE.svg'
import CredentialsCard from "./cards/CredentialsCard";


export default function Layout() {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [stateClass, setStateClass] = useState<String>('');
  const navigate = useNavigate();

  
  const handleMenuClick = useCallback(() => {
    setOpenMenu(prevOpenMenu => !prevOpenMenu);
    setStateClass(prevStateClass => prevStateClass === 'animate' ? 'reverse' : 'animate');
  }, []);

  


  return (
        <><div className={`nav ${openMenu ? 'border' : ''}`}>
        <div className='icon'><NameIcon className="clickable"  onClick={() => navigate(`/home`)}/></div>
        <div key={stateClass as Key} className={`hamburger ${stateClass}`} onClick={handleMenuClick}>
      <Hamburger />
    </div>
      </div>
    <div className="app-container">
      <div className="app-content">
     
        
        {openMenu && (
            <div className="page-container">
            <div className="menu">
              <div className="links">
              <Link to="/Home" onClick={handleMenuClick}>Home</Link>
              <Link to="/Projects" onClick={handleMenuClick}>Projecten</Link>
              <Link to="/cv" onClick={handleMenuClick}>Curiculum Vitae</Link>
              <Link to="/Contact" onClick={handleMenuClick}>Contact</Link>
              
              </div>
              <CredentialsCard />
            </div>
            </div>
          )}
        {!openMenu && (
            <div className="page-container">
              {/* content of the pages */}
              <Outlet />
            </div>
          )}
          
        
      </div>
    </div>
    {openMenu ? null : <div className="footer">
            <CredentialsCard />
          </div> }
    </>
  );
}
