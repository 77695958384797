import { Opleiding } from "../types/opleiding";
import { Stadium } from "../types/stadium";
import { Tag } from "../types/tags";
import banner from '../../assets/imgs/projects/delaware/banner/banner.png';
import userStoryMap from '../../assets/imgs/projects/delaware/USerStoryMap.jpg';
import moscow from '../../assets/imgs/projects/delaware/moscow.png';
import dcd from '../../assets/imgs/projects/delaware/dcd.png';
import mockups from '../../assets/imgs/projects/delaware/mockups.jpg';
import androidApp from '../../assets/imgs/projects/delaware/android-app.jpg';
import dotnetApp from '../../assets/imgs/projects/delaware/dotnet-app.png';
import { ProjectModel } from "../../models/projectModel";
import { USERS } from "../users/users";

const DELAWARE_PROJECTS: ProjectModel =   {
    id: "2",
    naam: "Delaware bestelplatform",
    opleiding: Opleiding.ToegepasteInformatica,
    stadium: Stadium.toegepaste_3,
    tags: [Tag.DEV_first, Tag.DOTNET, Tag.ANDROID],
    inleiding: `<p>Als groepsproject hebben we in samenwerking met Delaware een track&trace app gemaakt voor hun klanten. Ze hadden behoefte aan zowel een webapplicatie als een mobiele applicatie. De webapplicatie was gemaakt in React en de mobiele applicatie in Android Studio.</p>`,
    leerdoelen: [
      "Een actieve rol spelen in een groepswerk",
      "Leren werken met een externe klant",
      "Agile werkmethodes toepassen",
      "Een mobiele applicatie maken",
    ],
    proces: `<p>We hadden een externe klant die ons de nodige informatie gaf over wat ze nodig hadden. We hebben de agile werkmethodes toegepast en hebben regelmatig contact gehad met de klant om te controleren of we nog op schema lagen. Helaas waren er groepsleden die niet altijd even actief waren, waardoor er soms vertraging optrad. Na het maken van een eenvoudig wireframe zijn we begonnen met de webapplicatie. De mobiele applicatie was een grotere uitdaging, aangezien we nog nooit met Android Studio hadden gewerkt.</p>`,
    resultaat: `<p>Het resultaat was een applicatie waarbij een klant een bestelling kon plaatsen, zelf een pakket kon samenstellen (afmetingen, verdeling, etc.) en uiteindelijk dat pakket kon volgen. De desktop applicatie is gemaakt in .NET en de mobiele applicatie in Android Studio.</p>`,
    reflectie: `<p>Het project was een grote uitdaging, vooral omdat we nog nooit met Android Studio hadden gewerkt. De webapplicatie was een eenvoudige taak, maar de mobiele applicatie was een grotere uitdaging. Het was ook de eerste keer dat ik met een externe klant werkte, wat een heel andere ervaring was dan werken met een docent. Het project was een succes, maar er waren zeker verbeterpunten, zoals de communicatie tussen de groepsleden. Ik vond het jammer dat er groepsleden waren die niet even actief waren als de rest, wat tot frustraties leidde bij de rest van de groep.</p>`,
    gebruikte_middelen: [Tag.DOTNET, Tag.ANDROID],
    journey: [
        {
            titel: "User Story Map",
            beschrijving:
            "Het project begon met het maken van een user story map om de verschillende stappen van de applicatie te bepalen. Dit was een belangrijke stap om de structuur en functionaliteit van de applicatie te bepalen.",
            content: userStoryMap,
        },
        {
            titel: "Databank Schema",
            beschrijving:
            "Na het bepalen van de functionaliteiten van de applicatie zijn we overgegaan naar het maken van een databankschema. Dit was een belangrijke stap om de structuur van de databank te bepalen en een duidelijk overzicht te krijgen van de verschillende tabellen en relaties.",
            content: dcd,
        },
        {
            titel: "Mockups",
            beschrijving:
            "Na het maken van het databankschema zijn we overgegaan naar het maken van mockups. Dit was een belangrijke stap om het design van de applicatie te bepalen en een duidelijk overzicht te krijgen van de verschillende onderdelen.",
            content: mockups,
        },
        {
            titel: "Android App",
            beschrijving:
            "Een voorbeeld van de vormgeving van de Android-app die de huisstijl van Delaware implementeert.",
            content: androidApp,
        },
        {
            titel: ".NET App",
            beschrijving:
            "Hier zie je een voorbeeldapplicatie die de huisstijl van Delaware implementeert.",
            content: dotnetApp,
        },
    ],
    groepswerk: true,
    leden: [USERS.Niels, USERS.Viktor, USERS.Ruben, USERS.Jonathan, USERS.Kasper],
    banner: banner,

    images : [
       {
        src: moscow,
        title: "Moscow",
        description: "Moscow",
       },
         {
          src: dcd,
          title: "DCD",
          description: "DCD",
         },
         {
          src: mockups,
          title: "Mockups",
          description: "Mockups",
         },
         {
          src: androidApp,
          title: "Android App",
          description: "Android App",
         },
         {
          src: dotnetApp,
          title: ".NET App",
          description: ".NET App",
         },
    ]
};

export default DELAWARE_PROJECTS;
