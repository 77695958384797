import { Opleiding } from "../types/opleiding";
import { Stadium } from "../types/stadium";
import { Tag } from "../types/tags";
import banner from '../../assets/imgs/projects/stage/banner/banner.png';
import simpleOutOfServiceLandingPage from '../../assets/imgs/projects/stage/landing.png';
import duurzaamheidComponent from '../../assets/imgs/projects/stage/duurzaam.png';
import infoComponent from '../../assets/imgs/projects/stage/polet.png';
import tevredenheidsPoll from '../../assets/imgs/projects/stage/tevredenheidspoll.png';
import DCOFinder from '../../assets/imgs/projects/stage/dcoFinder.png';
import { ProjectModel } from "../../models/projectModel";

const STAGE_PROJECT: ProjectModel = {
  id: "4",
  naam: "Stage Projecten",
  opleiding: Opleiding.ToegepasteInformatica,
  stadium: Stadium.toegepaste_3,
  tags: [Tag.DEV_first, Tag.REACT, Tag.DRUPAL, Tag.PHP],
  inleiding:
    "<p>Op stage heb ik gewerkt aan verschillende projecten:</p><ul><li>kleine features die zijn toegevoegd aan websites van klanten</li><li>Een webscraper</li><li>Eigen reactproject uitgewerkt en geïntegreerd in Drupal</li></ul>",
  leerdoelen: ["Leren werken met REACT", "Leren werken met API's"],
  proces:
    "<p>Ik werd vooral zelfstandig te werk gezet en werd geacht zelf implementaties te zoeken voor de opdrachten. Ik kon voor vragen terecht bij collega's die altijd bereid waren te helpen waar nodig. Ik heb veel opzoekwerk gedaan rondom Drupal om te kunnen helpen bij de websites van klanten. Ook het maken van een webscraper binnen PHP en Cron was nieuw voor mij en vereiste veel opzoekwerk.</p>",
  resultaat:
    "<p>Het resultaat was een website die voldeed aan de vereisten van de opdracht en mijn eigen API implementeerde.</p>",
  reflectie:
    "<p>De eerste keer werken met REACT vond ik een hele uitdaging. Het grootste deel van mijn tijd in het project ging naar het begrijpen van de concepten. De website zelf had maar een matig uitziende UI.</p>",
  gebruikte_middelen: [Tag.REACT, Tag.DRUPAL, Tag.PHP],
  journey: [
    {
      titel: "Simple Out of Service Landing Page",
      beschrijving: "Een simpele landing page die wordt getoond wanneer een website offline is",
      content: simpleOutOfServiceLandingPage,
    },
    {
      titel: "Duurzaamheid Component",
      beschrijving: "Een component die de duurzaamheid van een bedrijf weergeeft",
      content: duurzaamheidComponent,
    },
    {
      titel: "Info Component",
      beschrijving: "Een component die extra informatie weergeeft over een bedrijf",
      content: infoComponent,
    },
    {
      titel: "Tevredenheids Poll",
      beschrijving: "Een poll die wordt getoond aan klanten om hun tevredenheid te meten",
      content: tevredenheidsPoll,
    },
    {
      titel: "DCO Finder",
      beschrijving: "DCO Finder is een project waarbij gebruikers verschillende bedrijven die dienstencheques service aanbieden kunnen opzoeken en contacteren. Bedrijven kunnen zich ook op dit platform aanmelden en in contact komen met eventuele klanten. Voor dit project is ook een webscraper gebruikt om de info van alle bedrijven op te halen en in de databank te plaatsen.",
      content: DCOFinder,
    },
  ],
  groepswerk: false,

  banner: banner,
  images: [
    {
      src: simpleOutOfServiceLandingPage,
      title: "Simple Out of Service Landing Page",
      description: "Een simpele landing page die wordt getoond wanneer een website offline is",
    },
    {
      src: duurzaamheidComponent,
      title: "Duurzaamheid Component",
      description: "Een component die de duurzaamheid van een bedrijf weergeeft",
    },
    {
      src: infoComponent,
      title: "Info Component",
      description: "Een component die extra informatie weergeeft over een bedrijf",
    },
    {
      src: tevredenheidsPoll,
      title: "Tevredenheids Poll",
      description: "Een poll die wordt getoond aan klanten om hun tevredenheid te meten",
    },
    {
      src: DCOFinder,
      title: "DCO Finder",
      description: "DCO Finder is een project waarbij gebruikers verschillende bedrijven die dienstencheques service aanbieden kunnen opzoeken en contacteren. Bedrijven kunnen zich ook op dit platform aanmelden en in contact komen met eventuele klanten. Voor dit project is ook een webscraper gebruikt om de info van alle bedrijven op te halen en in de databank te plaatsen.",
    },
  ],
};

    export default STAGE_PROJECT;