import { Opleiding } from "../types/opleiding";
import { Stadium } from "../types/stadium";
import { Tag } from "../types/tags";
import banner from "../../assets/imgs/projects/hoppin/banner/banner.png";
import featureMoscow from "../../assets/imgs/projects/hoppin/moscow.png";
import flowchart from "../../assets/imgs/projects/hoppin/flowchart.png";
import wireframe from "../../assets/imgs/projects/hoppin/wireframes.png";
import prototype from "../../assets/imgs/projects/hoppin/prototype.png";
import detailExample from "../../assets/imgs/projects/hoppin/routeselect.png";
import { ProjectModel } from "../../models/projectModel";
import screens1 from '../../assets/imgs/projects/hoppin/screens1.png'
import screens2 from '../../assets/imgs/projects/hoppin/screens2.png'
import screens3 from '../../assets/imgs/projects/hoppin/scrrns3.png'

const HOPPIN_PROJECT: ProjectModel = {
  id: "8",
  naam: "Hoppin: plan je route met het openbaar vervoer",
  opleiding: Opleiding.DigitalExperienceDesign,
  stadium: Stadium.xd_1,
  tags: [Tag.DESIGN_first, Tag.FIGMA],
  inleiding:
    "<p>Hoppin, een bestaande app van de Vlaamse overheid omtrent deelvervoer, voldeed niet aan de gebruikersnoden en was zwak opgebouwd. In verband met de opleiding leek dat daarom een ideale opgave voor ons: ontwerp een nieuwe app met een betere gebruikersbeleving. Ik heb een compleet nieuw ontwerp gemaakt in Figma, vasthoudend aan de huisstijl van Hoppin. Het belangrijkste aan deze opdracht was het analyseren van de behoeften van de gebruiker. De app gaat over gedeeld vervoer, dus de gebruikerservaring rond het bestellen van verschillende openbaar vervoer vanuit één centraal punt was belangrijk.</p>",
  leerdoelen: [
    "Analyseren van gebruikersbehoeften",
    "Ontwerpen in Figma",
    "Focussen op gebruikerservaring",
  ],
  proces:
    "<p>Ik ben begonnen met het analyseren van de huidige app en de behoeften van de gebruiker. Ik heb een wireframe gemaakt en ben toen begonnen met het ontwerpen van de app in Figma. Ik heb regelmatig feedback gevraagd aan medestudenten en leerkrachten om te zien of ik nog op schema zat. Ook heb ik online usertests gemaakt om de bruikbaarheid van mijn concept te valideren.</p>",
  resultaat:
    "<p>Het resultaat was een volledig nieuw ontwerp van de Hoppin app. Ik heb de app een nieuwe look gegeven en de gebruikerservaring verbeterd. Het was een goede oefening om te zien hoe belangrijk het is om de behoeften van de gebruiker te analyseren en daarop te focussen.</p>",
  reflectie:
    "<p>Ik vond het een leuke ervaring om een bestaande app een nieuw ontwerp te geven. Het was een goede oefening om te zien hoe belangrijk het is om de behoeften van de gebruiker te analyseren en daarop te focussen. Het was ook de eerste keer dat ik een usertest heb gemaakt en dat was een interessante ervaring.</p>",
  gebruikte_middelen: [Tag.FIGMA],
  journey: [
    {
      titel: "Het heranalyseren van de behoeften van de gebruiker",
      beschrijving:
        "Het project begon met het analyseren van de huidige app en de behoeften van de gebruiker. Dit was een belangrijke stap om de structuur en functionaliteit van de app te bepalen en verbeteren.",
      content: featureMoscow,
    },
    {
      titel: "Een flowchart voor een duidelijke structuur",
      beschrijving:
        "Na het analyseren van de behoeften van de gebruiker zijn we overgegaan naar het maken van een flowchart. Dit was een belangrijke stap om de structuur van de app te bepalen en een duidelijk overzicht te krijgen van de verschillende stappen van de gebruiker.",
      content: flowchart,
    },
    {
      titel: "Wireframes als basis van het ontwerp",
      beschrijving:
        "Na het maken van de flowchart zijn we overgegaan naar het maken van wireframes. Dit was een belangrijke stap om de structuur van de app te bepalen en een look and feel te krijgen van het uiteindelijke ontwerp.",
      content: wireframe,
    },
    {
      titel: "Prototype",
      beschrijving:
        "Na de wireframes zijn we overgegaan naar het maken van een werkend prototype in Figma. Dit prototype werd gebruikt om usertests uit te voeren met gebruikers en feedback te krijgen over het ontwerp en de gebruikerservaring van de app.",
      content: prototype,
    },
    {
      titel: "Detail van het ontwerp",
      beschrijving:
        "Een voorbeeld van een detail van het ontwerp. Hier zie je een voorbeeld van de route selectie in de app.",
      content: detailExample,
    },
  ],
  groepswerk: false,
  banner: banner,
  images: [
    {
      src: featureMoscow,
      title: "Moscow",
      description: "De Moscow feature in Figma",
    },
    {
      src: flowchart,
      title: "Flowchart",
      description: "De flowchart van de app",
    },
    {
      src: wireframe,
      title: "Wireframes",
      description: "De wireframes van de app",
    },
    {
      src: prototype,
      title: "Prototype",
      description: "Het prototype van de app",
    },
    {
      src: detailExample,
      title: "Detail van het ontwerp",
      description: "Een detail van het ontwerp",
    },
    {
      src: banner,
      title: "Banner",
      description: "De banner van het project",
    },
    {
      src: screens1,
      title: "Screens",
      description: "Screens van de app",
    },
    {
      src: screens2,
      title: "Screens",
      description: "Screens van de app",
    },
    {
      src: screens3,
      title: "Screens",
      description: "Screens van de app",
    },
  ],
};

export default HOPPIN_PROJECT;


