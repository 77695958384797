import { ProjectModel } from "../../models/projectModel";
import { Tag } from "../types/tags";
import referenties from '../../assets/imgs/projects/gezoute-popcorn/referenties.png'
import basis from '../../assets/imgs/projects/gezoute-popcorn/basis.png'
import colors from '../../assets/imgs/projects/gezoute-popcorn/colors.png'
import vormgeving from '../../assets/imgs/projects/gezoute-popcorn/vormgeving.png'
import end from '../../assets/imgs/projects/gezoute-popcorn/end.png'
import banner from '../../assets/imgs/projects/gezoute-popcorn/banner.png'

const GEZOUTE_POPCORN: ProjectModel =
{
    id: "43",
    naam: "Gezoute Popcorn Podcast logo design",
    tags: [Tag.DESIGN_first],
    inleiding: "<p>Logo design voor de Gezoute Popcorn Podcast. De podcast is een initiatief van mij en mijn vrienden om een filmpodcast op te zetten.</p>",
    leerdoelen: ["Logo design met een specifiek doel"],
    proces: "<p>Dit is een klein passieprojectje. Ik ben begonnen met het analyseren van de naam en het concept om met een idee te komen. Het logo is doormate het procces geëvolueerd van een oudere 2d stijl naar een modernere 3d benadering die een frisse en kleurrijke toets aan het logo geeft.</p>",
    resultaat: "<p>Een kleurrijk en modern logo die de naam van de podcast verwerkt. Mijn vrienden en ik waren er zeer tevreden mee.</p>",
    reflectie: "<p>Dit was de eerste keer dat ik mijn nieuw verworven design skills heb kunnen gebruiken voor eigen initiatief, dit gaf veel voldoeining en zelfvertrouwen.</p>",
    gebruikte_middelen: [Tag.FIGMA],
    journey: [
        {
          titel: "Verzamelen van referenties",
          beschrijving:
          "Voor het logo ben ik begonnen met her verzamelen van referenties van popcorn en een manier om zoutkorrels weer te geven.",
          content: referenties,
        },
        {
            titel: "Maken van een basis 2d design",
            beschrijving:
            "Doormiddel van lineart en simpele vormen ben ik begonnen met het maken van een basis design en gezocht naar een font die hierbij paste.",
            content: basis,
          },
          {
            titel: "Verschillende vormgevingen",
            beschrijving:
            "Ik heb verschillende vormgevingen geprobeerd om te zien wat het beste werkte voor het logo.",
            content: vormgeving,
          },
          {
            titel: "Expirimeteren met kleuren en variaties",
            beschrijving:
            "Door het gebruik van verschillende kleuren en vormen wou ik een dynamischer gevoel aan het logo geven.",
            content: colors,
          },
          {
            titel: "Overgang naar een moderne 3d stijl",
            beschrijving:
            "Naar mijn gevoel zat de kleur en vorm goed, maar miste het logo nog een moderne touch. Dus ben ik aan de slag gegaan met schaduwen en diepte om het logo meer leven te geven.",
            content: end,
          },
       
      ],
    groepswerk: false,
    banner: banner,
}


export default GEZOUTE_POPCORN;