import profilePic2 from "../assets/imgs/profile-pic.png";
import profilePic from "../assets/imgs/profilePic.jpg";
import headerpic from "../assets/imgs/header-img.jpg";
import SchoolCard from "../components/cards/SchoolCard";
import developmentIcon from "../assets/type-icons/DEV.svg";
import designIcon from "../assets/type-icons/DESIGN.svg";
import hogentIcon from "../assets/imgs/HOGENT_Logo.png";
import thomasmoreIcon from "../assets/imgs/THOMASMORE_logi.png";
import projects from "../data/projects/projects";
import ProjectCard from "../components/cards/ProjectCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import scroller from "../assets/gifs/Scroll-Down-white.gif";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

const Home: React.FC = () => {
  const navigate = useNavigate();

  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const shuffledProjects = shuffleArray(projects);

  return (
    <div className="Home">
      <div className="heading">
        <div className="scroller">
          <img src={scroller} alt="scroll down gif" />
        </div>
        <h1>
          <span>CREATIVE</span> <span>DEVELOPER</span>
        </h1>
        <div className="img-and-info-container">
          <div className="img">
            <img src={headerpic} alt="profile picture" />
          </div>
          <div className="info">
            <p>
              Based in <span className="font tert " >Belgium</span>
            </p>
            <p>
              <span className="font sec">24 years</span> old
            </p>
            <p>
              Passionate about <span className="font tert italic">DESIGN</span> <br/> and <span className="font sec">DEVELOPMENT</span>
            </p>
          </div>
        </div>
      </div>

      <div className="projects">
        <div className="header">
          {" "}
          <h2>Projecten</h2>
          <a onClick={() => navigate("/Projects")}>{`Bekijk alle projecten`}</a>
        </div>

        <div className="project-cards">
          <Carousel responsive={responsive}>
            {shuffledProjects.map((project, index) => (
              <ProjectCard
                key={index}
                id={project.id}
                banner={project.banner}
                title={project.naam}
                tags={project.tags}
                isGroupWork={project.groepswerk}
              />
            ))}
          </Carousel>
        </div>
      </div>

      <div className="schools">
        <SchoolCard
          image={hogentIcon}
          name="Mobile & Enterprise Development"
          icon={developmentIcon}
          classname="dev"
        />
        <SchoolCard
          image={thomasmoreIcon}
          name="Digital Experience Design"
          icon={designIcon}
          classname="design"
        />
      </div>
    </div>
  );
};

export default Home;
