import { ProjectModel } from "../../models/projectModel";
import { Opleiding } from "../types/opleiding";
import { Stadium } from "../types/stadium";
import { Tag } from "../types/tags";
import banner from '../../assets/imgs/projects/fluvius/banner/banner.jpg'
import useCase from '../../assets/imgs/projects/fluvius/usecases.png'
import databankSchema from '../../assets/imgs/projects/fluvius/databankschema.png'
import wireframe from '../../assets/imgs/projects/fluvius/wireframe.jpg'
import mockup from '../../assets/imgs/projects/fluvius/mockup2.jpg'
import reactApp from '../../assets/imgs/projects/fluvius/react-app.jpg'
import javaApp from '../../assets/imgs/projects/fluvius/java-app.jpg'
import { USERS } from "../users/users";

const FLUVIUS_PROJECT: ProjectModel = 
{
  id: "7",
  naam: "Fluvius informatiebord",
  opleiding: Opleiding.ToegepasteInformatica,
  stadium: Stadium.toegepaste_2,
  tags: [Tag.DEV_first, Tag.JAVA, Tag.REACT, Tag.NODE],
  inleiding:
    "<p>Fluvius had nood aan een informatiebord dat de werknemers op de hoogte hield van verschillende data. Dit informatiebord diende opgesteld te kunnen worden door Fluvius zelf.</p>",
  leerdoelen: [
    "Leren werken met REACT",
    "Leren werken met Java/JavaFX",
    "Leren werken met een klant",
  ],
  proces:
    "<p>Fluvius had een duidelijk beeld van wat ze nodig hadden. We hebben een wireframe gemaakt en zijn begonnen met de implementatie. We hadden regelmatig meetings met de klant om te zien of we nog op schema zaten. We hebben een informatiebord gemaakt in REACT dat de nodige informatie toonde aan de hand van grafieken. Om Fluvius in staat te stellen deze zelf te maken, is er een Java app ontwikkeld.</p>",
  resultaat:
    "<p>Het resultaat was een informatiebord dat voldeed aan de vereisten van de opdracht en de klant tevreden stelde. Het was een goede oefening om met een klant te werken en te zien hoe een project verloopt in de echte wereld.</p>",
  reflectie:
    "<p>Ik vond het een leuke ervaring om met een klant te werken. Het was een heel andere ervaring dan werken met een leerkracht. Het groepswerk verliep helaas bij sommige mensen heel stroef en enkele groepsleden zijn van het project gehaald.</p>",
  gebruikte_middelen: [Tag.JAVA, Tag.REACT, Tag.NODE, Tag.CYPRESS],
  journey: [
    {
      titel: "Use Cases",
      beschrijving:
      "Het project begon met het maken van use cases om de verschillende functionaliteiten van het informatiebord te bepalen. Dit was een belangrijke stap om de structuur en functionaliteit van het informatiebord te bepalen.",
      content: useCase,
    },
    {
      titel: "Databank Schema",
      beschrijving:
      "Na het bepalen van de functionaliteiten van het informatiebord zijn we overgegaan naar het maken van een databankschema. Dit was een belangrijke stap om de structuur van de databank te bepalen en een duidelijk overzicht te krijgen van de verschillende tabellen en relaties.",
      content: databankSchema,
    },
    {
      titel: "Wireframe",
      beschrijving:
      "Na het maken van het databankschema zijn we overgegaan naar het maken van een wireframe. Dit was een belangrijke stap om de structuur van het informatiebord te bepalen en een look and feel te krijgen van het uiteindelijke product.",
      content: wireframe,
    },
    {
      titel: "Mockup",
      beschrijving:
      "Na het maken van het wireframe zijn we overgegaan naar het maken van een mockup. Dit was een belangrijke stap om het design van het informatiebord te bepalen en een duidelijk overzicht te krijgen van de verschillende onderdelen.",
      content: mockup,
    },
    {
      titel: "React App",
      beschrijving:
      "Voor de implementatie van het informatiebord hebben we gekozen voor React. Hierdoor kunnen gebruikers alle data op een eenvoudige manier zien.",
      content: reactApp,
    },
    {
      titel: "Java App",
      beschrijving:
      "Voor de implementatie van de Java app hebben we gekozen voor JavaFX. Deze app dient om de data op het informatiebord te kunnen beheren en aanpassen.",
      content: javaApp,
    },
   
  ],
  groepswerk: true,
  leden: [USERS.Niels, USERS.Viktor, USERS.Niels, USERS.Arnoud],
  banner: banner,
  images : [
    {
    src: useCase,
    title: "Use Cases",
    description: "Use Cases",
     },
     {
      
      src: databankSchema,
      title: "Databank Schema",
      description: "Databank Schema",
     },
     {
      
    src: wireframe,
    title: "Wireframe",
    description: "Wireframe",
    }
   ]
};

export default FLUVIUS_PROJECT;