import { Stack, stackIcons } from "../../data/types/stack";
import figmaIcon from '../../assets/icons/figma.svg'
import { ReactNode } from "react";
import { Tag, tagIcons } from "../../data/types/tags";

interface StackCardProps {
    name: Tag;
}

const StackCard: React.FC<StackCardProps> = ({ name }) => {
    
      
    const stackIcon = tagIcons[name] || figmaIcon;

    return (
        <div className="stack-card">
            <img src={stackIcon} alt={name.toString()} className="stack-icon" />
            <div className="stack-name">{name}</div>
        </div>
    );
};

export default StackCard;