import { Opleiding } from "../types/opleiding";
import { Stadium } from "../types/stadium";
import { Tag } from "../types/tags";
import banner from "../../assets/imgs/projects/css-challenges/BANNER/banner.jpg";
import uselessWebDucky from "../../assets/imgs/projects/css-challenges/uselessWeb-rubber-ducky.png";
import uselessWebPigeon from "../../assets/imgs/projects/css-challenges/uselessWeb-Pigeon.png";
import wordLogoComputer from "../../assets/imgs/projects/css-challenges/wordLogo-computer.png";
import wordLogoRain from "../../assets/imgs/projects/css-challenges/wordLogo-rain.gif";

const CSSPROJECTS_PROJECT = {
  id: "11",
  naam: "CSS Projects",
  opleiding: Opleiding.DigitalExperienceDesign,
  stadium: Stadium.xd_1,
  tags: [Tag.DEV_first, Tag.DESIGN_second, Tag.SASS],
  inleiding:
    "<p>Tijdens de opleiding Digital Experience Design was er een vak die ons uitdaagde door verschillende css challenges uit te voeren. De opdrachten :</p> <ul> <li>The UselessWeb : ... because some websites, we just couldn't do without. [<a target=_blank href='https://theuselessweb.com/'>original</a>]</li> <li>Css Zengarden : A homage to css [<a target=_blank href='https://csszengarden.com/'>original</a>]</li> <li>Word logo's : Een logo van een woord die het woord incorporeert</li> <li>Dynamic Poster : Een poster met een dynamisch aspect</li> </ul>",
  leerdoelen: ["Een beter begrip krijgen van CSS en SASS"],
  proces:
    "We kregen om de aantal weken een nieuwe challenge, er werden een aantal voorbeelden getoond en wij moesten deze dan zelf proberen evenaren met een uniek idee.</p> <p>De uitgevoerde projecten zijn ook <a target=”_blank” href='https://dylanbracke.github.io/noctem-projects/' >hier</a> te bezichtigen</p>",
  resultaat:
    "<p>Het resultaat was een aantal leuke css challenges waaraan ik heb gewerkt en waarbij de creativiteit kan vloeien. Het was een leuke manier om te oefenen met CSS en SASS en om te zien wat er allemaal mogelijk is met CSS,SASS en CSS animaties.</p>",
  reflectie:
    "<p>Ik vond het een leuke ervaring om verschillende css challenges uit te voeren en vrijheid te hebben. Het was ook een goede oefening om te zien hoe je met een uniek idee een simpele opdracht kan omvormen tot iets unieks.</p>",
  gebruikte_middelen: [Tag.SASS],
  journey: [
    {
      titel: "Useless Web - Rubber Ducky Debugger",
      beschrijving:
        "De Rubber Ducky Debugger is een helpende hand die je 'helpt' bij het debuggen van je code.",
      content: uselessWebDucky,
    },
    {
      titel: "Useless Web - Flying Pigeon",
      beschrijving:
        "Met de flying pigeon kan je jezelf even afleiden van het werk en genieten van een vliegende duif die hier en daar iets naar beneden laat vallen op de druk van een knop.",
      content: uselessWebPigeon,
    },
    {
      titel: "Word Logo - Computer",
      beschrijving:
        "Computer speelt in op het oude design van de eerste computers.",
      content: wordLogoComputer,
    },
    {
      titel: "Word Logo- Rain",
      beschrijving:
        "Rain probeert het gevoel van regen terug te geven in het logo.",
      content: wordLogoRain,
    },
  ],
  groepswerk: false,

  banner: banner,
  images: [
    {
      src: uselessWebDucky,
      title: "Useless Web - Rubber Ducky",
      description:
        "Een voorbeeld van een useless web pagina. Hier zie je een voorbeeld van een rubber ducky.",
    },
    {
      src: uselessWebPigeon,
      title: "Useless Web - Pigeon",
      description:
        "Een voorbeeld van een useless web pagina. Hier zie je een voorbeeld van een pigeon.",
    },
    {
      src: wordLogoComputer,
      title: "Word Logo - Computer",
      description:
        "Een voorbeeld van een word logo. Hier zie je een voorbeeld van een computer.",
    },
    {
      src: wordLogoRain,
      title: "Word Logo - Rain",
      description:
        "Een voorbeeld van een word logo. Hier zie je een voorbeeld van rain.",
    },
  ],
};

export default CSSPROJECTS_PROJECT;
