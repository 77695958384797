import { useNavigate } from "react-router-dom";
import { Tag, tagIcons } from "../../data/types/tags";
import group from '../../assets/imgs/group.png'

interface ProjectCardProps {
  id: string;
  banner: string;
  title: string;
  tags: Tag[];
  isGroupWork: boolean;
}

const ProjectCard: React.FC<ProjectCardProps> = ({id, banner, title, tags, isGroupWork }) => {
  const truncatedTags = tags.slice(0, 4);
  const navigate = useNavigate();
  return (
    <div className='target clickable' onClick={() => navigate(`/projects/${id}`)}>
    <div className="project-card">
      <img src={banner} alt="Project Banner" className="project-card__banner" />
      <div className="info">
        <div className="project-card__title">{isGroupWork === true ? <img src={group} className="group-icon" alt="" /> : null} {title}</div>
        <div className="project-card__tags">
          {truncatedTags.map((tag, index) => (
            <div key={index} className="project-card__tag">
              <img src={tagIcons[tag]} alt={tag.toString()} style={{ display: tag === "" ? 'none' : '' }} />
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
};

export default ProjectCard;
