import React from 'react';

const Contact: React.FC = () => {
    const handleContactMe = () => {
        window.open('mailto:BrackeDylan@gmail.com');
    };

    return (
        <div className='Contact'>
            <h1>Contact</h1>
            <p>Wil je contact met me opnemen, iets vragen of gewoon hallo zeggen? Aarzel niet en probeer contact met me op te nemen! Ik zal mijn best doen om zo snel mogelijk te reageren.</p>
            <button className='btn' onClick={handleContactMe}>Neem contact op</button>
        </div>
    );
};

export default Contact;